import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'redux-query-react';
import { includes } from 'lodash';
import classNames from 'classnames';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import ControlCheckbox from 'andbeauty-ui/lib/Components/ControlCheckbox';
import Icon from 'andbeauty-ui/lib/Components/Icon';
import CheckboxList from 'andbeauty-ui/lib/Applications/eBeauty/Components/CheckboxList';
import { IStoreState } from '../../helpers/rootReducer';
import { ISampleProduct, ISampleProductDataConfigResponse } from 'components/SampleProduct/requests/DataConfigRequest';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';
import useCartSummary from '../../hooks/useCartSummary';
import AddToCartRequest from 'components/Checkout/requests/AddToCartRequest';
import Toaster from 'andbeauty-ui/lib/Components/Toaster';

interface IProps {
    config: {
        tosPage?: string;
        labels: {
            freeSample: string;
            tosLinkLabel: string;
            addToCart: string;
            noThanks: string;
            failedToAdd: string;
            youMayChoose: string;
        };
    };
}

interface ISelectedSampleProduct extends ISampleProduct {
    price: number;
    qty: number;
}

export const mapStateToProps = (state) => {
    return {
        sampleProductsDataConfig: state.entities.sampleProductsDataConfigRequest as ISampleProductDataConfigResponse,
    };
};
const SampleProductPopup = (props: IProps) => {
    const { config } = props;
    const { tosPage, labels } = config;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const [{}, addToCartRequest] = useMutation((url, quoteId, product) => AddToCartRequest(url, quoteId, product));
    const { quoteId, addToCartUri } = useCartSummary();
    const { sampleProductsDataConfig } = useSelector(mapStateToProps);
    const [selectedProducts, setSelectedProducts] = useState<{ [key: string]: ISelectedSampleProduct }>({});
    const [allowedSamplesQty, setAllowedSamplesQty] = useState(0);
    const [youMayChooseCallout, setYouMayChooseCallout] = useState<string>();

    const closePopup = () => {
        setSelectedProducts({});
        dispatch(closeOverlay({ name: 'all' }));
    };

    const selectProduct = (product: ISampleProduct) => {
        const updatedList = { ...selectedProducts };
        const selectedProduct = { ...product, qty: 1, price: 0, type_id: 'simple' };
        const availableSamplesCount = sampleProductsDataConfig.availableSamplesQty;

        if (!updatedList[selectedProduct.sku]) {
            if (Object.keys(selectedProducts).length < availableSamplesCount) {
                updatedList[selectedProduct.sku] = selectedProduct;
            }
        } else {
            delete updatedList[selectedProduct.sku];
        }

        setAllowedSamplesQty(availableSamplesCount - Object.keys(updatedList).length);
        setSelectedProducts(updatedList);
    };

    const handleAddToCart = async () => {
        const requests: Promise<any>[] = [];
        await Object.keys(selectedProducts).forEach((sku) => {
            const selectedProduct = selectedProducts[sku];
            requests.push(addToCartRequest(addToCartUri, quoteId, selectedProduct));
        });

        Promise.all(requests).then((responses) => {
            const addedToCartProducts: Array<{}> = [];

            responses.forEach((response) => {
                if (response.status === 200) {
                    const product = response.body;

                    addedToCartProducts.push({
                        productId: product.id,
                        sku: product.sku,
                        name: product.name,
                        qty: product.qty,
                        price: product.price,
                        itemListId: '',
                        itemListName: 'Sample Products Popup',
                    });
                } else {
                    Toaster.addToast({
                        intent: 'danger',
                        text: `${labels.failedToAdd}: ${response.body.message}`,
                        asHtml: true,
                    });
                }
            });

            if (addedToCartProducts.length) {
                setSelectedProducts({});
                window.dispatchEvent(
                    new CustomEvent('cartUpdated', {
                        detail: {
                            action: 'add-to-cart',
                            batchProducts: addedToCartProducts,
                        },
                    }),
                );

                if (allowedSamplesQty === 0) {
                    closePopup();
                }
            }
        });
    };

    useEffect(() => {
        if (sampleProductsDataConfig?.availableSamplesQty) {
            setAllowedSamplesQty(sampleProductsDataConfig.availableSamplesQty);
        }
    }, [sampleProductsDataConfig]);

    useEffect(() => {
        setYouMayChooseCallout(labels.youMayChoose.replace('%1', allowedSamplesQty.toString()));
    }, [allowedSamplesQty]);

    return (
        <Overlay
            isOpen={includes(openOverlays, 'sampleProductPopup')}
            doClose={closePopup}
            layout="dialog"
            className="ebeauty-dialog wide"
            customHeader={
                <div className="ebeauty-dialog-heading">
                    <h2>{labels.freeSample}</h2>
                    <button onClick={closePopup}>
                        <Icon kind="close02" width={16} height={16} />
                    </button>
                </div>
            }
        >
            <div className="ebeauty-dialog-content">
                {youMayChooseCallout && <RenderHTML html={`<p>${youMayChooseCallout}</p>`} nowrapper />}
                {tosPage && (
                    <a href={tosPage} target="_blank">
                        {labels.tosLinkLabel}
                    </a>
                )}

                <CheckboxList className="margt vertical in3cols">
                    {sampleProductsDataConfig?.sampleProducts.map((product) => (
                        <div className="layout-products-compact" key={product.sku}>
                            <div
                                className={classNames('box-product selectable', {
                                    selected: selectedProducts[product.sku],
                                })}
                            >
                                <ControlCheckbox
                                    checked={selectedProducts[product.sku] !== undefined}
                                    onChange={() => selectProduct(product)}
                                />
                                <div className="box-product__main" role="button" onClick={() => selectProduct(product)}>
                                    <div className="box-product__image">
                                        <span style={{ backgroundImage: `url('${product.image}')` }} />
                                    </div>
                                    <div className="box-product__content">
                                        <div className="box-product__text">
                                            <div className="box-product__brand">{product.brand}</div>
                                            <div className="box-product__title">{product.name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </CheckboxList>
                <div className="product-actions">
                    <Buttons className="main" layout="none">
                        <Button intent="primary" title={labels.addToCart} onClick={handleAddToCart} />
                        <Button intent="secondary" title={labels.noThanks} onClick={closePopup} />
                    </Buttons>
                </div>
            </div>
        </Overlay>
    );
};

export default SampleProductPopup;
